import React from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';
import { Link } from 'gatsby';
import {
  faTwitter,
  faGoogle,
  faApple,
  faReddit,
  faDiscord
} from '@fortawesome/free-brands-svg-icons';
import {
  faGlobe,
  faArrowUpRightFromSquare
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StaticImage } from 'gatsby-plugin-image';
import { Tabs, Tab, Row, Col } from 'react-bootstrap';
import { YoutubeEmbed } from '../../modules/common/components/youtube-embed';
import { CategoryCard } from '../../modules/cs/main/components/category-card';

import '../generic-page.scss';

const HSRAboutPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="hsr">
      <ul className="breadcrumb">
        <li>
          <Link to="/star-rail/">Honkai: Star Rail</Link>
        </li>
        <li className="divider">/</li>
        <li>About the game</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/starrail/categories/category_about.png"
            alt="About the game"
          />
        </div>
        <div className="page-details">
          <h1>About Honkai: Star Rail</h1>
        </div>
      </div>
      <div className="page-content">
        <SectionHeader title="Honkai: Star Rail" />
        <p>
          Honkai: Star Rail is a new HoYoverse space fantasy RPG. Hop aboard the
          Astral Express and experience the galaxy's infinite wonders on this
          journey filled with adventure and thrills. Players will meet new
          companions across various worlds and even chance upon some familiar
          faces. Together, they will overcome the struggles caused by the
          Stellaron and unravel the hidden truths behind it.
        </p>
        <p>
          Honkai: Star Rail is a turn-based RPG very similar to old-school JRPGs
          (especially the Final Fantasy series). The game is split into two
          modes: overworld and battle. In the overworld, you run around the map
          and when you encounter and enemy group, you will 'transfer' into the
          battle mode where you have to defeat them in turn-based combat.
        </p>
        <p>
          For more information about the combat, classes, elements do check our
          Introduction to the game guide:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Introduction to the game"
            link="/star-rail/guides/introduction-to-the-game"
            image={
              <StaticImage
                src="../../images/starrail/categories/category_introgame.png"
                alt="Introduction to the game"
              />
            }
          />
        </Row>
        <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
        <SectionHeader title="Gameplay" />
        <Row className="video">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="TTs7XDGcLCk" />
          </Col>
        </Row>
        <SectionHeader title="Official links" />
        <Tabs
          defaultActiveKey="Global"
          transition={false}
          id="current-events-tabs"
          className="current-event-tabs"
        >
          <Tab eventKey="Global" title="Global">
            <Row>
              <Col xs="12" xl="8" xxl="6">
                <div className="list-box">
                  <div className="list-single-row">
                    <div className="list-category">
                      <FontAwesomeIcon width="18" icon={faGlobe} /> Website
                    </div>
                    <div className="list-value">
                      <a
                        href="https://hsr.hoyoverse.com/en-us/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          width="18"
                          icon={faArrowUpRightFromSquare}
                        />{' '}
                        Open in new window
                      </a>
                    </div>
                  </div>
                  <div className="list-single-row">
                    <div className="list-category">
                      <FontAwesomeIcon width="18" icon={faTwitter} /> Twitter
                    </div>
                    <div className="list-value">
                      <a
                        href="https://twitter.com/honkaistarrail"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          width="18"
                          icon={faArrowUpRightFromSquare}
                        />{' '}
                        Open in new window
                      </a>
                    </div>
                  </div>
                  <div className="list-single-row">
                    <div className="list-category">
                      <FontAwesomeIcon width="18" icon={faGoogle} />
                      Google Play
                    </div>
                    <div className="list-value">-</div>
                  </div>
                  <div className="list-single-row">
                    <div className="list-category">
                      <FontAwesomeIcon width="18" icon={faApple} /> Apple Store
                    </div>
                    <div className="list-value">-</div>
                  </div>
                  <div className="list-single-row">
                    <div className="list-category">
                      <FontAwesomeIcon width="18" icon={faReddit} /> Reddit
                    </div>
                    <div className="list-value">
                      <a
                        href="https://www.reddit.com/r/HonkaiStarRail/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          width="18"
                          icon={faArrowUpRightFromSquare}
                        />{' '}
                        Open in new window
                      </a>
                    </div>
                  </div>
                  <div className="list-single-row">
                    <div className="list-category">
                      <FontAwesomeIcon width="18" icon={faDiscord} /> Discord
                    </div>
                    <div className="list-value">
                      <a
                        href="https://discord.gg/honkaistarrail"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon width="18" icon={faDiscord} /> Open in
                        new window
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Tab>
        </Tabs>
      </div>
    </DashboardLayout>
  );
};

export default HSRAboutPage;

export const Head: React.FC = () => (
  <Seo
    title="About the game | Honkai: Star Rail | Prydwen Institute"
    description="Learn more about Honkai: Star Rail."
  />
);
